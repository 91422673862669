exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-ai-pays-tsx": () => import("./../../../src/pages/ai-pays.tsx" /* webpackChunkName: "component---src-pages-ai-pays-tsx" */),
  "component---src-pages-ai-rewards-tsx": () => import("./../../../src/pages/ai-rewards.tsx" /* webpackChunkName: "component---src-pages-ai-rewards-tsx" */),
  "component---src-pages-black-scholes-model-tsx": () => import("./../../../src/pages/black-scholes-model.tsx" /* webpackChunkName: "component---src-pages-black-scholes-model-tsx" */),
  "component---src-pages-brightnews-tsx": () => import("./../../../src/pages/brightnews.tsx" /* webpackChunkName: "component---src-pages-brightnews-tsx" */),
  "component---src-pages-brix-token-tsx": () => import("./../../../src/pages/brix-token.tsx" /* webpackChunkName: "component---src-pages-brix-token-tsx" */),
  "component---src-pages-brix-tsx": () => import("./../../../src/pages/brix.tsx" /* webpackChunkName: "component---src-pages-brix-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-huge-apr-tsx": () => import("./../../../src/pages/huge-apr.tsx" /* webpackChunkName: "component---src-pages-huge-apr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-japan-tsx": () => import("./../../../src/pages/japan.tsx" /* webpackChunkName: "component---src-pages-japan-tsx" */),
  "component---src-pages-litepaper-tsx": () => import("./../../../src/pages/litepaper.tsx" /* webpackChunkName: "component---src-pages-litepaper-tsx" */),
  "component---src-pages-mint-brix-tsx": () => import("./../../../src/pages/mint-brix.tsx" /* webpackChunkName: "component---src-pages-mint-brix-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-soon-tsx": () => import("./../../../src/pages/soon.tsx" /* webpackChunkName: "component---src-pages-soon-tsx" */),
  "component---src-pages-staking-tsx": () => import("./../../../src/pages/staking.tsx" /* webpackChunkName: "component---src-pages-staking-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "component---src-pages-warren-buffet-tsx": () => import("./../../../src/pages/warren-buffet.tsx" /* webpackChunkName: "component---src-pages-warren-buffet-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */),
  "component---src-pages-zero-fees-tsx": () => import("./../../../src/pages/zero-fees.tsx" /* webpackChunkName: "component---src-pages-zero-fees-tsx" */)
}

